import React from "react";
import CountUp from "react-countup";
import ReactFullpage from "@fullpage/react-fullpage";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";
import Customfooter from "../components/footer-wo-main";
import GetDagcoins from "../components/get-dagcoins";
import Header from "../components/header";
import Layout from "../components/layout";
import { Seo } from "../components/seo";
import DagS from "../images/dag-shadow.inline.svg";
import FivePercent from "../images/fivepercent.inline.svg";
import NineBillion from "../images/full.inline.svg";
import SixBillion from "../images/sixbillion.inline.svg";

function Distribution({ data }) {
  var NextUid = data.prismicDistribution.alternate_languages[0].uid;

  if (data.prismicDistribution.lang === "en-us") {
    var NextUrl = "/et/" + NextUid;
    var TotalAmount = "TOTAL AMOUNT OF DAGS";
    var TotalDag = "450 million dags";
    var TotalDist = "FOR DISTRIBUTION";
    var TotalPartners = "FOR USABILITY PARTNERS";
  } else if (data.prismicDistribution.lang === "et-ee") {
    NextUrl = "/" + NextUid;
    TotalAmount = "KOKKU";
    TotalDag = "450 miljonit dagcoin’i";
    TotalDist = "JAOTAMISEKS";
    TotalPartners = "KASUTUSPARTNERLUSTEKS";
  } else {
    NextUrl = "wrong lang";
    TotalAmount = "TOTAL AMOUNT OF DAGS";
    TotalDag = "450 million dags";
    TotalDist = "FOR DISTRIBUTION";
    TotalPartners = "FOR USABILITY PARTNERS";
  }

  var SeoMeta = data.prismicDistribution.data;
  return (
    <Layout htmllang={data.prismicDistribution.lang}>
      <Header htmllang={data.prismicDistribution.lang} langswitcher={NextUrl} />
      <ReactFullpage
        //fullPage options
        licenseKey={"D3D3B393-33AC48F2-A43C09B6-A2FFA11D"}
        scrollOverflow={true}
        responsiveWidth={"992"}
        scrollingSpeed={1000}
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <div className="section first-section-distribution">
                <div className="distru">
                  <div className="distru-wrapper">
                    <video
                      width="470"
                      height="255"
                      autoPlay="autoplay"
                      controls
                      muted
                    >
                      <source
                        src="https://prismic-io.s3.amazonaws.com/dagcoin/45a26d8b-9034-4e54-9da2-b4b6083a8181_distrubution.mp4"
                        type="video/mp4"
                      />
                      <source
                        src="https://prismic-io.s3.amazonaws.com/dagcoin/45a26d8b-9034-4e54-9da2-b4b6083a8181_distrubution.mp4"
                        type="video/ogg"
                      />
                      <source
                        src="https://prismic-io.s3.amazonaws.com/dagcoin/45a26d8b-9034-4e54-9da2-b4b6083a8181_distrubution.mp4"
                        type="video/webm"
                      />
                      <object
                        data="https://prismic-io.s3.amazonaws.com/dagcoin/45a26d8b-9034-4e54-9da2-b4b6083a8181_distrubution.mp4"
                        width="470"
                        height="255"
                      >
                        <embed
                          src="https://prismic-io.s3.amazonaws.com/dagcoin/45a26d8b-9034-4e54-9da2-b4b6083a8181_distrubution.mp4"
                          width="470"
                          height="255"
                        />
                      </object>
                    </video>
                  </div>
                  <div className="above-distru">
                    <PrismicRichText
                      field={data.prismicDistribution.data.above_title.richText}
                    />
                    <div className="inside-distru">
                      <DagS />
                      <h3>{SeoMeta.distributed_title.text}</h3>
                      <h2>
                        <CountUp
                          delay={3}
                          start={0.0}
                          end={SeoMeta.coins_distributed.text}
                          duration={6}
                          separator=" "
                          decimals={2}
                          decimal="."
                          suffix="%"
                        ></CountUp>
                      </h2>
                      <p>{SeoMeta.available_coins.text}</p>
                      <h4>{SeoMeta.total_coins.text}</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section" id="second-distribution">
                <div className="disclistwrap" role="region">
                  <div className="disclist">
                    <PrismicRichText
                      field={
                        data.prismicDistribution.data.nine_billion_dagcoins
                          .richText
                      }
                    />
                  </div>
                  <div className="discimage longone">
                    <div className="basesircle">
                      <div className="basesircle-1">
                        <NineBillion />
                      </div>
                    </div>
                    <div className="discarea">
                      <h4 className="counterto">
                        <CountUp
                          delay={5}
                          start={0.0}
                          end={9000000000}
                          duration={6}
                          separator=","
                          decimals={0}
                          decimal="."
                          suffix=""
                        ></CountUp>
                      </h4>
                      <h5>{TotalAmount}</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section">
                <div className="disclistwrap" role="region">
                  <div className="disclist">
                    <PrismicRichText
                      field={data.prismicDistribution.data.just_five.richText}
                    />
                  </div>
                  <div className="discimage">
                    <div className="basesircle">
                      <div className="basesircle-1">
                        <FivePercent />
                      </div>
                    </div>
                    <div className="discarea">
                      <h4>
                        <CountUp
                          delay={7}
                          start={0.0}
                          end={5}
                          duration={6}
                          separator=""
                          decimals={0}
                          decimal="."
                          suffix="%"
                        ></CountUp>
                      </h4>
                      <h5>{TotalDag}</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section">
                <div className="disclistwrap" role="region">
                  <div className="disclist">
                    <PrismicRichText
                      field={
                        data.prismicDistribution.data.three_billion.richText
                      }
                    />
                  </div>
                  <div className="discimage">
                    <div className="basesircle">
                      <div className="basesircle-1">
                        <GatsbyImage
                          alt="Dagcoin"
                          image={
                            data.treebillionimg.childImageSharp.gatsbyImageData
                          }
                        />
                      </div>
                    </div>
                    <div className="discarea">
                      <h4 className="counterto">
                        <CountUp
                          delay={10}
                          start={0.0}
                          end={3000000000}
                          duration={6}
                          separator=","
                          decimals={0}
                          decimal="."
                          suffix=""
                        ></CountUp>
                      </h4>
                      <h5>{TotalDist}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section">
                <div className="disclistwrap" role="region">
                  <div className="disclist">
                    <PrismicRichText
                      field={data.prismicDistribution.data.six_billion.richText}
                    />
                  </div>
                  <div className="discimage">
                    <div className="basesircle">
                      <div className="basesircle-1">
                        <SixBillion />
                      </div>
                    </div>
                    <div className="discarea">
                      <h4 className="counterto">
                        <CountUp
                          delay={14}
                          start={0.0}
                          end={6000000000}
                          duration={6}
                          separator=","
                          decimals={0}
                          decimal="."
                          suffix=""
                        ></CountUp>
                      </h4>
                      <h5>{TotalPartners}</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section lastpart-footer-section">
                <Customfooter htmllang={data.prismicDistribution.lang} />
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </Layout>
  );
}

export const Head = ({ data }) => {
  const { seo_title, seo_description } = data.prismicDistribution.data;
  return (
    <>
      <Seo
        description={seo_description.text}
        pathname={data.prismicDistribution.uid}
        title={seo_title.text}
        // lang={data.prismicDistribution.lang}
      />
    </>
  );
};
export const pageQuery = graphql`
  query ($uid: String, $lang: String) {
    prismicDistribution(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      lang
      alternate_languages {
        id
        lang
        type
        uid
      }
      data {
        total_coins {
          richText
          text
        }
        three_billion {
          richText
          text
        }
        six_billion {
          richText
          text
        }
        seo_title {
          text
          richText
        }
        seo_description {
          text
          richText
        }
        nine_billion_dagcoins {
          text
          richText
        }
        just_five {
          text
          richText
        }
        distributed_title {
          text
          richText
        }
        coins_distributed {
          text
          richText
        }
        available_coins {
          text
          richText
        }
        above_title {
          text
          richText
        }
      }
    }
    treebillionimg: file(relativePath: { eq: "treebillionimg.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
  }
`;
export default Distribution;
